<template>
  <el-dialog
    v-bind="{title:editData?'编辑平台':'新建平台',width:'420px'}"
    v-dialogDrag
    @open="handlerOpen"
    @closed="handlerClose"
    :visible.sync="visibleDialog">
    <el-form ref="form" label-width="130px" :model="submitForm" :rules="rules">

      <el-form-item label="平台名称" prop="sysName">
        <el-input class="w-200" v-model.trim="submitForm.sysName" clearable placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="域名" prop="domain">
        <el-input class="w-200" v-model.trim="submitForm.domain" clearable placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="备注" prop="remark">
        <el-input class="w-200" v-model.trim="submitForm.remark" clearable placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="平台描述" prop="sysDesc">
        <el-input class="w-200" v-model.trim="submitForm.phone" clearable maxlength="11" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="平台类型" prop="plateType">
        <el-select class="w-200" v-model.trim="submitForm.plateType" placeholder="请选择">
          <el-option v-for="item in plateTypes" :key="item.id" :value="item.id" :label="item.name"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="系统图标">
        <UploadPic
          :src="submitForm.sysPicURL"
          @uploaded="submitForm.sysPicURL=$event.nowRelatUrl"
          @remove="submitForm.sysPicURL=''"
        />
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visibleDialog=false">取 消</el-button>
      <el-button type="primary" @click="submit" :loading="loading">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { updatePlatform } from '@/apis/authManage/platform'
import { plateTypes } from '@/constants/authManage/platform'
import UploadPic from '@/components/UploadPic'
export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
      require: true
    },
    editData: {
      type: Object
    },
    permList: {
      type: Array,
      default () {
        return []
      }
    }
  },
  computed: {
    visibleDialog: {
      set (newValue) {
        this.$emit('update:visible', newValue)
      },
      get () {
        return this.visible
      }
    },
    permListFormat () {
      return this.permList
    }
  },
  data () {
    return {
      loading: false,
      orgas: [],
      comps: [],
      platecs: [], // 平台
      plateTypes: plateTypes,
      submitForm: {
        plateId: '', // 平台id
        domain: '', // 域名
        plateType: plateTypes[0].id, // 平台类型
        remark: '', // 备注
        sysDesc: '', // 平台描述
        sysName: '', // 平台名称
        sysPicURL: '' // 平台图标
      },
      rules: {
        sysName: { required: true, message: '请输入平台名称' },
        domain: { required: true, message: '请输入域名' }
      }
    }
  },
  methods: {
    submit () {
      const submitForm = { ...this.submitForm }
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.loading = true
          updatePlatform(submitForm).then((res) => {
            this.loading = false
            if (res.code == 200) {
              this.$message.success('操作成功！')
              this.$emit('refresh')
              this.visibleDialog = false
            } else {
              this.$message.error(res.msg)
            }
          })
        } else {
          return false
        }
      })
    },

    handlerOpen () {
      if (this.editData) {
        Object.keys(this.submitForm).forEach((key) => {
          const value = this.editData[key]
          if (value) this.submitForm[key] = value
        })
      }
    },

    handlerClose () {
      this.$emit('update:editData', null)
      const sourceForm = this.$options.data(this).submitForm
      this.submitForm = Object.assign({}, this.submitForm, sourceForm)
      this.$refs.form.resetFields()
    }
  },
  components: {
    UploadPic
  }
}
</script>
