<template>
  <PageContainer>
    <BaseSearchForm
      @search="onSearch"
      @reset="onReset"
      :loading="loading">
      <el-form-item label="平台名称:">
        <el-input v-model.trim="searchForm.sysName" placeholder="请输入" clearable></el-input>
      </el-form-item>
    </BaseSearchForm>

    <BaseTable
      id="platform"
      @page-change="handlePageChange"
      :tablePage="tablePage"
      :loading="loading"
      :data="tableData"
    >
      <template #buttons>
        <el-button type="primary" size="small" icon="el-icon-plus" @click="editVisible=true">新 增</el-button>
      </template>
      <vxe-column field="plateId" title="序号" align="center" width="80"></vxe-column>
      <vxe-column field="sysName" title="平台名称" min-width="200"></vxe-column>
      <vxe-column field="domain" title="平台域名" min-width="200"></vxe-column>
      <vxe-column title="平台图标">
        <template #default={row}>
          <div class="flex align-center justify-center w-100-p h-24" >
            <img class="system-icon-item" v-if="row.sysPicURL" :src="getMediaUrl(row.sysPicURL)">
          </div>
        </template>
      </vxe-column>
      <vxe-column field="sysDesc" title="平台描述"></vxe-column>
      <vxe-column field="opertime" title="操作时间"></vxe-column>
      <vxe-column title="操作" width="120">
        <template #default={row}>
          <el-button type="primary" @click="handleEdit(row)" >编辑</el-button>
          <el-button type="danger" @click="handleDelete(row)" >删除</el-button>
        </template>
      </vxe-column>
    </BaseTable>

    <!-- 新增或编辑 -->
    <EditPlatform :visible.sync="editVisible" :edit-data.sync="editData" @refresh="initList" />
  </PageContainer>
</template>

<script>
import { getPlatformList, delPlatform } from '@/apis/authManage/platform'
import { plateTypes } from '@/constants/authManage/platform'
import EditPlatform from './EditPlatform'
export default {
  data () {
    return {
      searchForm: {
        sysName: '' // 平台名称
      },
      // 列表相关
      loading: false,
      tableData: [],
      tablePage: {
        pageNum: 1,
        pageSize: 20,
        totalSize: 0
      },
      // 编辑相关
      editData: null,
      editVisible: false
    }
  },
  created () {
    this.initList()
  },
  methods: {
    onReset () {
      const source = this.$options.data(this).searchForm
      this.searchForm = Object.assign({}, this.searchForm, source)
      this.onSearch()
    },

    onSearch () {
      this.tablePage.pageNum = 1
      this.initList()
    },

    initList () {
      this.loading = true
      const { totalSize, ...pageParams } = this.tablePage
      const params = Object.assign({}, this.searchForm, pageParams)
      getPlatformList(params).then((res) => {
        this.loading = false
        if (res.code == 200) {
          this.tableData = res.data.rows.map((item) => {
            const target = plateTypes.find(({ id }) => id == item.plateId) || { name: '' }
            item.plateTypeName = target.name
            item.activityName = item.activity == 0 ? '禁用' : '启用'
            item.isLogName = item.isLog == 0 ? '否' : '是'
            item.statusName = item.status == 0 ? '删除' : '存在'
            return item
          })
          this.tablePage.totalSize = res.data.totalSize
        } else {
          this.$message.error(res.msg)
        }
      })
    },

    handlePageChange ({ currentPage, pageSize }) {
      this.tablePage.pageNum = currentPage
      this.tablePage.pageSize = pageSize
      this.initList()
    },

    handleEdit (row) {
      this.editData = JSON.parse(JSON.stringify(row))
      this.editVisible = true
    },

    goEditAccount (row) {
      this.editData = JSON.parse(JSON.stringify(row))
      this.editVisible = true
    },

    handleDelete ({ plateId }) {
      this.$confirm('此操作将永久删除该平台, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        delPlatform({ ids: [plateId] }).then((res) => {
          if (res.code == 200) {
            this.initList()
            this.$message.success('刪除成功！')
          } else {
            this.$message.error(res.msg)
          }
        })
      })
    }
  },
  components: {
    EditPlatform
  }
}
</script>

<style lang="scss" scoped>
.system-icon-item {
  object-fit: contain;
  max-width: 95px;
  max-height: 24px;
}
</style>
