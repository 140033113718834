
import http from '@/utils/http'

export function updatePlatform (p) {
  return http.post('/basefun/platecs/set', p)
}

export function getPlatformList (p) {
  return http.post('/basefun/platecs/list', p)
}

export function delPlatform (p) {
  return http.post('/basefun/platecs/del', p)
}
